export const trackerCurbStore = {
    state: {
        name: 'tracker_curb',
        title: 'Tracker Curb',
        titlePlural: 'Tracker Curbs',
        nav: {
            base: 'tracker_curb',
            newView: false,
            hideSearch: true,
            hideUpload: true,
            views: [

            ]
        },
        search: {
            endPoint: 'tracker_invoice',
            formFields: {},
            resultFields: {}
        },
        object: {
            fields: []
        }
    }
}