<template>
    <div>
        <h1>{{curbData.nomenclature}}</h1>
        <p>{{ curbData.desc }}</p>
        <div v-show="curbData.nomenclature">
            <table class="table">
                <thead>
                <tr>
                    <th>Order</th>
                    <th>Tracker Invoice ID</th>
                    <th>Curb</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td><router-link :to="`/tracker/${curbData.tracker_id}`">{{ curbData.tracker_id }}</router-link></td>
                    <td>{{ curbData.tracker_invoice_id }}</td>
                    <td><router-link :to="`/curb/${curbData.curb_id}`">{{ curbData.curb_id }}</router-link></td>
                </tr>
                </tbody>
            </table>

            <table class="table">
                <tbody>
                <tr>
                    <th class="datatable">Curb Type</th>
                    <td>{{ curbData.curb_type }}</td>
                </tr>
                <tr>
                    <th class="datatable">Old Model</th>
                    <td>{{ curbData.old_model }}</td>
                </tr>
                <tr>
                    <th class="datatable">New Model</th>
                    <td>{{ curbData.new_model }}</td>
                </tr>
                </tbody>
            </table>

            <h3>Drawings</h3>
            <table class="table">
                <tbody>
                <tr v-for="drawing in drawings" :key="drawing.cdi_file_id">
                    <ProtectedLink :type="'download'" :linkType="'link'" :text="drawing.title" :id="drawing.cdi_file_id" />
                </tr>
                </tbody>
            </table>

        </div>

        <div v-show="!curbData.nomenclature" class="alert alert-danger">No curb selected!</div>
    </div>
</template>

<script>

import httpHelpers from "@/store/httpHelpers";
import {store} from "@/store/BusinessObject.store";
import {trackerCurbStore} from "@/components/businessObjects/tracker_curb/TrackerCurbStore";
import ProtectedLink from "@/components/utils/ProtectedLink.vue";

export default {
    name: 'Container',
    components: {ProtectedLink},
    data() {
        return {
            state: store.state,
            trackerCurbStore: trackerCurbStore.state,
            curbData: {
                nomenclature: '',
            },
            drawings: []
        }

    },
    methods: {
        getCurbData: function() {
            if (this.$props.id) {
                let scope = this;
                httpHelpers.post('/tracker_invoice/to_json/' + scope.$props.id).then(function(res) {
                    scope.curbData = res.data.tracker_curb;
                    scope.drawings = res.data.drawings;
                })
            } else {
                this.curbData.nomenclature = 'Invalid Curb'
            }

        }
    },
    props: ['id'],
    created() {
        store.initialize(this.trackerCurbStore)
        this.state.hideNav = true;
        this.getCurbData();
    }
}

</script>

<style scoped>
    .datatable {
        width: 200px !important;
    }
</style>